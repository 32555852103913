import { useState } from "react"
import RtSearch from "components/input/RtSearch"
import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import HostEventsList from "./host-event-list"
import { ACTIVE_EVENTS, PAST_EVENTS, LIST_STYLE_CAROUSEL, LIST_STYLE_GRID } from "constants/global.constants"
import SectionContainer from "components/layout/SectionContainer"
import FiltersLayout from "components/responsive-layouts/FiltersLayout"

function MyEvents() {
  const { searchParams, handleSetUrlParams } = useRtSetUrlParams()
  const q = searchParams.get("q")
  
  const [activeEventsStyle, setActiveEventsStyle] = useState<"carousel" | "grid">("carousel")
  const [pastEventsStyle, setPastEventsStyle] = useState<"carousel" | "grid">("grid")

  const handleViewStyleChange = (isActive: typeof ACTIVE_EVENTS | typeof PAST_EVENTS) => {
    if (isActive === ACTIVE_EVENTS) {
      setActiveEventsStyle(activeEventsStyle === LIST_STYLE_CAROUSEL ? LIST_STYLE_GRID : LIST_STYLE_CAROUSEL)
    } else {
      setPastEventsStyle(pastEventsStyle === LIST_STYLE_CAROUSEL ? LIST_STYLE_GRID : LIST_STYLE_CAROUSEL)
    }
  }

  return (
    <div>
      <SectionContainer>
        <FiltersLayout>
          <RtSearch
            handleSearch={(value: string) => handleSetUrlParams({ q: value })}
            searchValue={q || ""}
          />
        </FiltersLayout>
      </SectionContainer>

      <HostEventsList 
        isActive={ACTIVE_EVENTS} 
        listStyle={activeEventsStyle}
        onViewStyleChange={handleViewStyleChange}
      />
      <HostEventsList 
        isActive={PAST_EVENTS} 
        listStyle={pastEventsStyle}
        onViewStyleChange={handleViewStyleChange}
      />
    </div>
  )
}

export default MyEvents
