import { NETWORK_ERROR_CODE, SUPPORT_EMAIL } from "constants/global.constants"
import API from "./api.util"
import { showToaster } from "./show-toaster.util"

// Handle GET requests
export const handleGetRequest = async (url: string, params?: any) => {
  try {
    console.log(params)
    const response = await API.get(url, { params })
    return response.data // Return data directly
  } catch (error) {
    handleError(error)
    throw error // Rethrow to propagate the error
  }
}

// Handle POST requests
export const handlePostRequest = async (url: string, data: any) => {
  try {
    const response = await API.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response
  } catch (error) {
    handleError(error)
    throw error
  }
}

// Handle PUT requests (added for editing profile)
export const handlePutRequest = async (url: string, data: any) => {
  try {
    const response = await API.put(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return response
  } catch (error) {
    handleError(error)
    throw error
  }
}

// Handle DELETE requests
export const handleDeleteRequest = async (url: string) => {
  try {
    const response = await API.delete(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response
  } catch (error) {
    handleError(error)
    throw error
  }
}

// Handle successful API responses
export function handleApiSuccess(response: any) {
  const message = response.data.success_message

  if (message) {
    showToaster("success", message)
  }
}

// General error handling function
export function handleError(err: any) {
  // Handle network error
  if (err.code === NETWORK_ERROR_CODE) {
    showToaster(
      "error",
      "You are offline",
      3000,
      "top-center",
      NETWORK_ERROR_CODE,
    )
    return
  }

  const { response } = err

  // Handle server errors
  if (response) {
    // Handle validation errors (422 status)
    if (response.status === 417 && response.data?.error_message?.includes('Validation')) {
      let message = response.data.error_message
        .replace('Validation Errors:', '')
        .trim()

      // If it's a ticket quantity validation error, show the standard message
      if (message.toLowerCase().includes('qty') || 
          message.toLowerCase().includes('maximum of 10 tickets')) {
        message = 'You can only purchase a maximum of 10 tickets at a time'
      }

      showToaster("error", message)
      return
    }

    // Handle 404 error
    if (response.status === 404) {
      showToaster("error", "Sorry, the requested resource does not exist")
      return response
    }

    if (response.status >= 500) {
      showToaster(
        "error",
        `We're experiencing a temporary hiccup. Please try again in 5 minutes. If the challenge persists, contact our dedicated support team at ${SUPPORT_EMAIL}`,
      )
      return
    }

    // Handle API errors with an error message
    if (response.data && response.data.error_message) {
      showToaster(
        "error",
        response.data.error_message,
        5000,
        undefined,
        response.data.error_message,
      )
      return
    }
  }

  // Fallback error message
  showToaster("error", "An unexpected error occurred. Please try again later.")
}
