import { useMutation } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { addCheckoutUrl } from "redux/features/cart"
import { handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"

export function useProceedToCheckout() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [search] = useSearchParams()

  const isAdminSellTickets = search.get("sell-tickets")

  const mutation = useMutation({
    mutationFn: async ({ slug, tickets }: { slug: string; tickets: {}[], eventName: string }) => {
      const response = await handlePostRequest(
        `/events/${slug}/secure-tickets`,
        { tickets },
      )

      return response
    },
    onSuccess: (response, { slug, eventName }) => {
      showToaster("success", "You have successfully secured the tickets")

      const { lock_identifier, expires_at, is_authenticated } =
        response?.data.data

      dispatch(
        addCheckoutUrl({
          slug,
          eventName,
          checkOutUrl: `/checkout/${slug}/${expires_at}/${lock_identifier}`,
        }),
      )

      if (!is_authenticated) {
        navigate("/login", {
          state: {
            isProceedToCheckout: true,
            slug,
            eventName,
            expiresAt: expires_at,
            lockId: lock_identifier,
          },
        })

        return
      }

      if (isAdminSellTickets) {
        navigate(`/sell-tickets/${slug}/${lock_identifier}`)
        return
      }

      navigate(`/checkout/${slug}/${expires_at}/${lock_identifier}`)
    },
    onError: (err: any) => {
      throw err
    },
  })

  return mutation
}
